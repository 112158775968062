import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { t } from '@/utility/localization';

export const ReadMoreSingleLine = ({ className, children, maxHeight }) => {
  const contentContainer = useRef(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  useEffect(() => {
    if (contentContainer?.current) {
      setIsOverflowing(
        contentContainer?.current?.scrollHeight >
          contentContainer?.current?.clientHeight ||
          contentContainer?.current?.scrollWidth >
            contentContainer?.current?.clientWidth
      );
    }
  }, [contentContainer, children]);

  return (
    <div
      className={classnames([
        'flex items-end overflow-hidden overflow-ellipsis',
        className
      ])}>
      <div
        ref={contentContainer}
        className="break-words"
        style={{
          maxHeight: isExpanded ? '100%' : maxHeight,
          width: isExpanded ? '100%' : 'calc(100% - 80px)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word'
        }}>
        {children}
      </div>
      {isOverflowing && (
        <button
          className="whitespace-nowrap bg-transparent text-neutral-50 outline-none"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}>
          {isExpanded ? t('hide') : t('see-more2')}
        </button>
      )}
    </div>
  );
};
