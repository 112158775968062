import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC, SyntheticEvent } from 'react';

//internal type for react-markdown
type ReactMarkdownProps = {
  children: string;
  components: object;
  className?: string;
};

const ReactMarkdown = dynamic<ReactMarkdownProps>(
  () => import('react-markdown')
);

export type MarkdownProps = {
  children: string;
  className?: string;
  components?: object;
  onLinkClick?: (event: SyntheticEvent) => void;
};

const Markdown: FC<MarkdownProps> = ({
  children,
  onLinkClick,
  className,
  components = {}
}) => {
  return (
    <ReactMarkdown
      className={className}
      components={{
        p: ({ children }) => <p>{children}</p>,
        a: ({ children, href }) => (
          <Link
            href={href}
            passHref
            onClick={onLinkClick}
            className="font-semibold hover:underline">
            {children}
          </Link>
        ),
        ...components
      }}>
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
