import { useRef, useState } from 'react';

import { compressImageFile } from '@/utility/imageHelpers';
import { t } from '@/utility/localization';

import { uploadImageWithMultipart } from '@/services/uploadService';

import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import useApi from '@/hooks/useApi';

/**
 * Complementary component to allow user to upload their own image
 * @param bucket the target bucket to upload the file to
 * @param path the target path to upload the file to
 * @param uploadHint The hint to be displayed about the image size or dimension
 * @param onPickImage Callback called when user uploaded their image
 * @returns {JSX.Element}
 * @constructor
 */
const ByUserUpload = ({
  useCase,
  entityId,
  uploadHint,
  onPickImage,
  removeButtonLabel = ''
}) => {
  const fileRef = useRef(null);
  const [temporaryImage, setTemporaryImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const { isLoading, execute: executeUploadImageWithMultipart } = useApi(
    uploadImageWithMultipart
  );

  const uploadFile = (file) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      setTemporaryImage(e.target.result);

      const compressedImageFile = await compressImageFile(file);

      const { data } = await executeUploadImageWithMultipart({
        useCase: useCase,
        associatedEntityId: entityId,
        image: compressedImageFile
      });
      if (data?.imageUrl) {
        onPickImage(data.imageUrl, compressedImageFile);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    uploadFile(file);
  };
  const handleDragover = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsDragging(true);
  };
  const openFilePicker = () => {
    fileRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    uploadFile(file);
  };

  const handleRemoveImage = () => {
    onPickImage(null);
  };

  return (
    <div className="">
      <div className="text-neutral-100 text-center text-14">
        {uploadHint}
      </div>

      <div
        className="relative mt-24 flex flex-col items-center justify-center gap-8 overflow-hidden rounded-lg border border-dashed border-neutral-70 bg-neutral-97 p-24 text-center font-semibold"
        onDrop={handleDrop}
        onDragOver={handleDragover}
        onDragLeave={() => setIsDragging(false)}>
        <div>
          <Icon name="upload-cloud-01" width={32} height={32} />
        </div>
        <div>{t('drag-drop-to-upload')}</div>
        <div className="mb-16 text-body-sm font-normal text-neutral-40">
          {t('supported-files-png-jpg-jpe')}
        </div>
        <button
          type="button"
          className="underline"
          onClick={openFilePicker}>
          {t('add-from-computer')}
        </button>
        <input
          accept=".png, .jpg, .jpeg"
          type="file"
          ref={fileRef}
          className="hidden"
          onChange={handleFileChange}
        />

        {temporaryImage && (
          <div className="absolute left-0 top-0 z-1 flex h-full w-full items-center justify-center bg-white-default">
            <img
              src={temporaryImage}
              alt="Uploading"
              className="absolute left-0 top-0 h-full w-full object-cover "
            />

            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white-default bg-opacity-70 backdrop-blur-md">
              {t('uploading1')}
            </div>
          </div>
        )}

        {isDragging && (
          <div className="pointer-events-none absolute left-0 top-0 z-1 flex h-full w-full items-center justify-center rounded-lg bg-neutral-97 p-24">
            {t('drop-to-upload')}
          </div>
        )}
      </div>
      {removeButtonLabel && (
        <div className="mt-24">
          <NPLButton
            hierarchy="destructive_outline"
            onClick={handleRemoveImage}
            buttonText={removeButtonLabel}
            size="md"
          />
        </div>
      )}
    </div>
  );
};

export default ByUserUpload;
