import style from './ConfirmationModal.module.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LoadingCircle from '@/components/common/LoadingCircle';
import Modal from '@/components/common/Modal';
import NPLButton from '@/components/npl/NPLButton';

const ConfirmationModal = ({
  title = '',
  subtitle = '',
  isOpen = false,
  primaryBtnText = '',
  secondaryBtnText = '',
  primaryBtnOnClick = null,
  secondaryBtnOnClick = null,
  primaryBtnHierarchy = 'outline',
  secondaryBtnHierarchy = 'accent_primary',
  primaryBtnLoading = false,
  secondaryBtnLoading = false,
  isLoading = false,
  onClose = () => {},
  showCloseIcon = true,
  primaryBtnTestId = '',
  deleteTestId = ''
}) => {
  const className = 'c-ConfirmationModal';

  return (
    <Modal
      size="md"
      open={isOpen}
      showCloseIcon={showCloseIcon}
      onClose={onClose}
      customInnerClass="!p-0"
      customContainerClass={style[className]}>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div
          className="font-poppins p-24"
          onClick={(e) => e.stopPropagation()}>
          <p className="mb-24 text-heading-md font-semibold">{title}</p>
          <p className="mb-24 text-body-md text-npl-text-icon-on-light-surface-secondary/65">
            {subtitle}
          </p>
          <div className="flex flex-col items-end justify-end gap-12 md:flex-row md:gap-16">
            {primaryBtnText && (
              <div>
                <NPLButton
                  data-testid={deleteTestId}
                  hierarchy={primaryBtnHierarchy}
                  onClick={async (e) => {
                    await primaryBtnOnClick?.(e);
                    onClose?.();
                  }}
                  size="lg"
                  buttonText={primaryBtnText}
                  loading={primaryBtnLoading}
                />
              </div>
            )}
            {secondaryBtnText && (
              <div>
                <NPLButton
                  data-testid={primaryBtnTestId}
                  hierarchy={secondaryBtnHierarchy}
                  onClick={async (e) => {
                    await secondaryBtnOnClick?.(e);
                    onClose?.();
                  }}
                  size="lg"
                  buttonText={secondaryBtnText}
                  loading={secondaryBtnLoading}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  primaryBtnClassName: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  secondaryBtnClassName: PropTypes.string,
  primaryBtnOnClick: PropTypes.func,
  secondaryBtnOnClick: PropTypes.func,
  isLoading: PropTypes.bool
};

export default ConfirmationModal;
