import PropTypes from 'prop-types';
import React from 'react';
import QRCode from 'react-qr-code';

import LoadingCircle from '@/components/common/LoadingCircle';
import Text from '@/components/common/Text';

const className = 'c-QrCodeComponent';

const QrCodeComponent = ({
  qrCodeImageProps,
  points,
  isImage,
  qrCodeLink,
  loading,
  qrCodeSize = {
    width: '146px',
    height: '146px',
    size: 146
  }
}) => {
  return (
    <div className={`${className} mt-24 flex flex-row items-center`}>
      <div className="relative mb-48 aspect-square min-w-[164px] max-w-[168px] rounded-4 border-2 border-neutral-70 p-8">
        {isImage && qrCodeImageProps?.src && (
          <img src={qrCodeImageProps.src} alt={qrCodeImageProps.alt} />
        )}

        {!isImage && qrCodeLink && (
          <div className="mr-24">
            <QRCode
              style={{
                height: qrCodeSize?.height,
                width: qrCodeSize?.width
              }}
              size={qrCodeSize?.size}
              value={qrCodeLink}
            />
          </div>
        )}
        {loading && (
          <div className="absolute left-0 top-0 h-full w-full">
            <LoadingCircle />
          </div>
        )}
      </div>
      {points && points.length && (
        <ol className="mx-24 ml-48 mt-12 list-decimal">
          {points.map((point, index) => (
            <li className="mb-12" key={index}>
              <Text
                text={point}
                customClassName="text-label-lg font-medium text-neutral-10 leading-24"
              />
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

QrCodeComponent.propTypes = {
  qrCodeImageProps: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  })
};

export default QrCodeComponent;
