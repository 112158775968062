import React, { useEffect } from 'react';

import { getAppLinkForChallenge, openInNewTab } from '@/utility/helpers';
import { t } from '@/utility/localization';

import QrCodeComponent from '@/features/ConnectChatPlatform/components/QrCodeComponent';

import { useAuthContext } from '@/contexts/AuthContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import { showConfetti } from '@/components/common/ConfettiContainer';
import Markdown from '@/components/common/Markdown';
import Modal from '@/components/common/Modal';
import NextImage from '@/components/common/NextImage';
import MobileDrawer from '@/components/member/MobileDrawer';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

function SuccessChallengeModal({
  challengeInfo,
  communityInfo,
  open,
  onClose,
  renderDaysDateRange
}) {
  const { user } = useAuthContext();
  const { isGtEqMd } = useWindowWidthContext();
  const appLink = getAppLinkForChallenge(
    communityInfo,
    challengeInfo,
    user ? { email: user.email } : null
  );

  const isAlwaysOnChallenge = challengeInfo?.challengeType !== 'ALWAYS_ON';

  const renderInnerContent = () => {
    return (
      <div className="flex flex-col justify-end md:flex-row">
        <div className="w-full max-w-[440px] px-24 py-32">
          <div className="flex flex-col">
            <Icon name="tick" width={80} height={80} />
            <p className="mb-4 mt-4 text-heading-sm font-semibold text-npl-text-icon-on-dark-secondary">
              {t('youre-all-set-for')}
            </p>
            <p className="text-heading-lg font-semibold text-npl-text-icon-on-dark-primary">
              {challengeInfo?.title}
            </p>
            {!isAlwaysOnChallenge && (
              <div className="mt-20">{renderDaysDateRange()}</div>
            )}
            <div className="aspect-h-9 aspect-w-16 relative mt-20">
              <NextImage
                alt="challenge banner image"
                mobileImgProps={{
                  src: challengeInfo?.cover,
                  layout: 'fill',
                  objectFit: 'cover',
                  priority: true
                }}
                className="rounded-12"
              />
            </div>
          </div>
        </div>
        {isGtEqMd ? (
          <div className="max-w-[330px] rounded-r-12 bg-white-default px-24  py-32">
            <div className="flex flex-col ">
              <div>
                <p className="mb-8 text-heading-sm font-semibold">
                  {t('challenges-are-best-experienced-on-the-nas-app')}
                </p>
                <p className="text-body-md">
                  <Markdown>
                    {t('scan-the-qr-code-below-or-visit-to-install', {
                      link: 'nas.io/install',
                      linkValue: appLink
                    })}
                  </Markdown>
                </p>
              </div>
              <div className="mx-auto text-center">
                <QrCodeComponent
                  qrCodeLink={appLink}
                  loading={!appLink}
                  qrCodeSize={{
                    width: '146px',
                    height: '146px',
                    size: 146
                  }}
                />
              </div>
              <div className="flex flex-col gap-20">
                <div className="border-b-1 border-npl-neutral-light-solid-4" />
                <div className="text-label-md font-medium text-npl-text-icon-on-light-surface-secondary">
                  {t('or-continue-on-desktop')}
                </div>
                <NPLButton
                  hierarchy="accent_primary"
                  onClick={onClose}
                  buttonText={t('go-to-challenge')}
                  size="lg"
                  stretch
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-32 mt-16 px-24">
            <div className="mb-32  text-body-md text-npl-text-icon-on-dark-primary">
              {t('challenges-are-best-experienced-on-the-nas-app')}
            </div>
            <div className="flex flex-col gap-12">
              <NPLButton
                size="xl"
                hierarchy="accent_primary"
                buttonText={t('open-in-app')}
                onClick={() => {
                  openInNewTab(appLink);
                  onClose();
                }}
                darkMode
                stretch
              />
              <NPLButton
                hierarchy="outline"
                onClick={onClose}
                multiply
                buttonText={t('continue-on-browser')}
                size="xl"
                stretch
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  // Show celebration confetti on modal open
  useEffect(() => {
    //don't show confetti if the community id is '668e83ba9a4941d788d01ff2' (/money)
    if (communityInfo?._id !== '668e83ba9a4941d788d01ff2') showConfetti();
  }, []);

  return isGtEqMd ? (
    <Modal
      open={open}
      customContainerClass="!p-0 !bg-npl-transparent-white-25 !bg-blur-2xl !rounded-12"
      customInnerClass="!p-0"
      containerBackgroundTransparent
      showCloseIcon={false}
      onClose={onClose}>
      {renderInnerContent()}
    </Modal>
  ) : (
    <MobileDrawer
      open={open}
      showCloseIcon={false}
      withoutPadding={true}
      onClose={onClose}
      placeInnerContentBottom
      seeThroughContainer>
      {renderInnerContent()}
    </MobileDrawer>
  );
}

export default SuccessChallengeModal;
