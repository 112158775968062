import { useState } from 'react';

import { showErrorToast } from '@/components/common/ToastContainer';

type APIFunction = (params: unknown) => Promise<{
  data: unknown;
  error: unknown;
}>;

const useApi = (apiFunction: APIFunction) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = async (params: unknown) => {
    try {
      setIsLoading(true);
      const response = await apiFunction(params);

      if (response.error) {
        //show the error toast if the API has been failed
        showErrorToast(response.error);

        return { data: null, error: response.error };
      }

      return { data: response.data, error: null };
    } catch (error) {
      //capture the function error and show the error toast
      showErrorToast(error.message);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, execute };
};

export default useApi;
