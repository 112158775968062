import imageCompression from 'browser-image-compression';

export const compressImageFile = async (file) => {
  // Check if the file is valid and if it's an image
  if (!file || !file?.type.startsWith('image/')) {
    return;
  }

  const fileLimit = 1024 * 1024 * 0.5; //500kB

  if (file.size < fileLimit) {
    return file;
  }

  const defaultMaxIteration = 10;
  const extraIteration = Math.ceil(file.size / (1024 * 1024)) * 2;

  const options = {
    maxSizeMB: fileLimit,
    maxIteration: defaultMaxIteration + extraIteration,
    useWebWorker: true
  };

  const updatedFile = await imageCompression(file, options);

  return updatedFile;
};

export async function handleBlurImage(
  imageUrl,
  blurAmount = 30,
  maxDimension = 600,
  quality = 0.5
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      // Calculate scale factor
      const scaleFactor = Math.min(
        1,
        maxDimension / Math.max(img.width, img.height)
      );

      // Calculate new dimensions
      const newWidth = Math.round(img.width * scaleFactor);
      const newHeight = Math.round(img.height * scaleFactor);

      // Create blur canvas
      const blurCanvas = document.createElement('canvas');
      const blurCtx = blurCanvas.getContext('2d');

      // Set blur canvas size
      blurCanvas.width = img.width;
      blurCanvas.height = img.height;

      // Draw the image on the canvas
      blurCtx.drawImage(img, 0, 0);

      // Apply blur filter
      blurCtx.filter = `blur(${blurAmount}px)`;
      // Draw blur on the canvas
      blurCtx.drawImage(blurCanvas, 0, 0);

      // Create final canvas
      const finalCanvas = document.createElement('canvas');
      const finalCtx = finalCanvas.getContext('2d');

      // Set final canvas size
      finalCanvas.width = newWidth;
      finalCanvas.height = newHeight;

      finalCtx.drawImage(
        blurCanvas, // Source image
        0, // Source X
        0, // Source Y
        img.width, // Source width
        img.height, // Source height
        -30, // Destination X
        -30, // Destination Y
        newWidth + 60, // Destination width
        newHeight + 60 // Destination height
      );

      // Convert final image to base64 with specified quality
      const blurredBase64 = finalCanvas.toDataURL('image/jpeg', quality);
      resolve(blurredBase64);
    };
    img.onerror = reject;
    img.src = imageUrl;
  });
}
