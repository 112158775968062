import config from '@/utility/config';

import { getAxiosErrorMsg } from './helpers';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';

const { communityApiBasePath } = config;

/**
 *
 * @param {payload} payload
 * @returns
 */
export const uploadCustomImage = async (payload) => {
  try {
    const { data, error } = await adminProtectedAxiosRequest.post(
      `${communityApiBasePath}/api/v1/uploadImage`,
      payload
    );
    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
};

/**
 * Uploads an image using multipart form data.
 *
 * @param {Object} payload - The payload object containing `useCase`, `associatedEntityId`, and `image`
 * @param {string} payload.useCase - The use case for the image upload, we also can use entity types
 * @param {string} payload.associatedEntityId - The ID of the associated entity, this can be a user ID, post ID, etc.
 * @param {File} payload.image - The image file to be uploaded
 * @returns {Promise} - A promise that resolves to the response of the image upload request
 */
export const uploadImageWithMultipart = async ({
  useCase,
  associatedEntityId,
  image
}) => {
  const formData = new FormData();
  formData.append('useCase', useCase);
  formData.append('image', image);

  //entity id is optional
  if (associatedEntityId) {
    formData.append('associatedEntityId', associatedEntityId);
  }

  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/image/multipart`,
    formData
  );
};

export const uploadDocumentWithMultipart = async ({
  useCase,
  associatedEntityId,
  document
}) => {
  const formData = new FormData();
  formData.append('useCase', useCase);
  formData.append('document', document);

  //entity id is optional
  if (associatedEntityId) {
    formData.append('associatedEntityId', associatedEntityId);
  }

  const { data, error } = await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/document/multipart`,
    formData
  );
  return { data, error };
};
